import React from 'react'
import Styled from 'styled-components'

import { StaticQuery, graphql, Link} from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Seo from '../components/Seo'
import Main, {colors} from '../styles/Styles'
import Header from '../components/Header'
import Button from '../components/Button'
import Md from '../components/Markdown'
import Footer from '../components/Footer'

const query = graphql`
query {
	strapiServicesPage {
		Title
		ProjectsText
		ProjectsTitle
		MainTitle {
			data {
				childMarkdownRemark {
					html
					rawMarkdownBody
				}
			}
		}
		services {
			Title
			Description {
				data {
					childMarkdownRemark {
						html
					}
				}
			}
			Url
			Cover {
				alternativeText
				childStrapiCloudinaryBridge {
					gatsbyImageData
				}
			}
		}
		projects {
			Title
			Subtitle
			Url
			Cover {
				alternativeText
				childStrapiCloudinaryBridge {
					gatsbyImageData
				}
			}
		}
	}
}
`;

/*
function map (num, in_min, in_max, out_min, out_max) {
	return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}
*/

function ServicesPage ({strapiServicesPage : sp}){
	
	const [currentService, setService] = React.useState(0)
	const [darkness, setDarknessState] = React.useState(0)
	const [oldY, setY] = React.useState(0)
	
	// b.offsetHeight - top < 200 ? 0 : ...
	const setDarkness = (top) => setDarknessState( top > 400 ? .8 : 0 )
	
	React.useEffect(()=>{
		
		const getCurrentService = (direction) => {
			
			const b = document.getElementById(sp.services[currentService].Url)
			
			const top = !!b ? b.getBoundingClientRect().top : 0
			
			setDarkness(-top)
			
			if (-top > b.offsetHeight && currentService < sp.services.length-1 && direction > 0)
				
				return currentService+1
				
			else if (top >= 0 && currentService-1 >= 0 && direction < 0)
				
				return currentService-1
				
			return currentService
		}
		
		const serviceScroll = e => {
			
			const y = e.target.scrollingElement.scrollTop
			const direction = y - oldY
			
			const newCurrentService = getCurrentService(direction)
			
			if (typeof newCurrentService === "number" && newCurrentService !== currentService){
				
				// in mobile will scroll service navbar to current service
				if (window.innerWidth < window.innerHeight){
					const cont = document.getElementById('links')
					const currentLink = document.getElementsByClassName('link')[newCurrentService]
					cont.scrollLeft = currentLink.offsetLeft
				}
				
				setService(newCurrentService)
			}
			
			setY(y)
		}
		
		document.addEventListener('scroll', serviceScroll)
		
		return () => {
			document.removeEventListener('scroll', serviceScroll)
		}
	}, [currentService, sp.services, oldY])
	
	
	React.useEffect(() => {
		//determine currently viewing service after component did mount
		
		let i = 0
		
		//check if inpage link
		while (i < sp.services.length && !window.location.href.includes(sp.services[i].Url)) i++;
		
		//if no inpage look for current scroll height
		if (i === sp.services.length){
			
			const y = document.getElementsByTagName('html')[0].scrollTop
			
			const heights = sp.services.map(({Url}) => document.getElementById(Url).offsetHeight)
			
			let sum = heights[0]
			i=0
			
			//check scroll height against height service 1, then height service 1 + height service 2 ecc
			
			while ( i < heights.length && y > sum ) sum+=heights[++i];
			
			//give up
			if (i === sp.services.length)
				i=0
		}
		
		setService(i)
		setDarkness(-document.getElementById(sp.services[i].Url).getBoundingClientRect().top)
		
	}, [sp.services])

	return (
		<ServicesPageStyles darkness={darkness}>
			<Header/>
			
			<Seo title={sp.Title} description={sp.MainTitle.data.childMarkdownRemark.rawMarkdownBody} type="website"/>
			
			<Md>{sp.MainTitle.data.childMarkdownRemark.html}</Md>
			
			<section className="services">
				<div className="fixed">
					<div className="bgCont">
						<GatsbyImage image={getImage(sp.services[currentService].Cover.childStrapiCloudinaryBridge)} alt={sp.services[currentService].Cover.alternativeText}/>
						<div className="overlay"/>
					</div>
					
					<div className="links" id="links">
						{sp.services.map(({Title, Url}, id) =>
							<Link to={'#'+Url} className="link" key={Url}>
								<h5 className={id === currentService ? 'active' : ''}>
									{Title.toLowerCase()}<span>   </span>
								</h5>
							</Link>
						)}
					</div>
				</div>
				
				<div className="text">
					{sp.services.map(({Description, Url,  Cover}, id) =>
						<div id={Url} className="serviceText" key={id}>
							<GatsbyImage className="portraitImage" image={getImage(Cover.childStrapiCloudinaryBridge)} alt={Cover.alternativeText}/>
							<Md key={Url}>{Description.data.childMarkdownRemark.html}</Md>
						</div>
					)}
				</div>
			</section>
			
			
			<h1 className="projects">{sp.ProjectsTitle}</h1>
			<p className="projects">{sp.ProjectsText}</p>
			
			<section className="projects">
			{sp.projects.map(({Url, Title, Subtitle, Cover}) => 
				<Button url={"/projects/"+Url} title={Title} key={Url}>
					<div className="project">
						<div className="text">
							<h4>{Title}</h4>
							<p>{Subtitle}</p>
						</div>
						{!!Cover &&
						<GatsbyImage image={getImage(Cover.childStrapiCloudinaryBridge)} alt={Cover.alternativeText}/>
						}
					</div>
				</Button>
			)}
			</section>
			
			<Footer/>
		</ServicesPageStyles>
	)//
}

const ServicesPageStyles = Styled(Main)`

text-align:center;
display:flex;
flex-direction:column;
align-items:center;

white-space:pre;

h1{
	margin:17.36vw 0 14.58vw;	//250 210
	
	@media (orientation:portrait){
		margin:43.59vw 0 25.64vw;	//170 100
	}
}


section.services {
	width:100%;
	
	transition:.2s;
	text-align:left;
	
	color:${() => colors.white};
	
	@media (orientation:portrait){
		color:${() => colors.black};
	}
	
	@media (orientation:landscape){
		background:${() => colors.darkgrey};
	}
	
	.text {
		
		padding:0 11.5vw;	//165
		box-sizing:border-box;
		
		height:100%;
		margin: -100vh 0 0 19.44vw;	//280
		
		position:relative;
		z-index:10;
		
		.portraitImage {
			display:none;
			
			@media (orientation:portrait){
				display:block;
				
				width:100%;
			}
		}
		
		.serviceText {
			//padding instead of margin so that spaces on top is keeped with inpage links
			padding:120vh 0 0 0;
			
			p{
				white-space: break-spaces;
				font-size: 3.47vw;	//50
				line-height: 4.51vw;	//65
				
			}
			
			a {
				cursor:pointer;
			}
		}
		
		@media (orientation:portrait){
			margin:0;
			padding:0;
			margin-top:-30.8vw;	//120
			
			.serviceText {
				padding:calc(30.8vw + 3.85vw) 0 0;
				
				p {
					font-size: 5.13vw;	//20
					line-height: 6.41vw;	//25
				}
			}
		}
	}
	
	.fixed {
		position:sticky;
		width:100%;
		height:100vh;
		top:0;
		left:0;
		
		@media (orientation:portrait){
			height:30.8vw;	//	
			
			overflow:hidden;
			
			background: white;
			z-index: 50;
			
			border-bottom: .26vw solid ${() => colors.darkgrey};
		}
		
		.bgCont{
			position:relative;
			width:100%;
			height:100%;
			
			* {
				position:absolute;
				top:0;
				left:0;
				
				width:100%;
				height:100%;
				
				object-fit:cover;
			}
			
			@media (orientation:portrait){
				display:none;
			}
		}
		
		.overlay{
			transition:.2s;
			
			background:${() => colors.black};
			opacity:${props => props.darkness};
		}
		
		.links{
			
			top:12.22vw;
			left:11.5vw;
			
			position:absolute;
			
			@media (orientation:portrait){
				top:0;
				left:0;
				
				margin-top:17.95vw;
				
				width:100%;
				height:18vw;//70
				overflow-x:scroll;
				scroll-behavior:smooth;
				
			}
			
			a.link{
				cursor:pointer;
				
				@media (orientation:landscape){
					span {
						display:none;
					}
				}
				
				.active {
					color:${() => colors.red};
				}
				
				h5 {
					transition:.2s;
					margin:0;
					
					@media (orientation:portrait){
						display:inline-block;
					}
				}
			}
		}
	}
	
}

h1.projects{
	margin:20.83vw 0 0;	//300
	
	@media (orientation:portrait){
		margin-top:38.46vw;	//150
	}
}
p.projects{
	margin:.69vw 0 3.47vw;	//10 50
}

section.projects {
	box-sizing:border-box;
	width:100%;
	padding:0 11.5vw;
	
	margin-bottom:20.83vw;
	
	.styledButton{
		width:100%;
		height:14.72vw;	//212
		
		margin:1.74vw 0;	//25
	}
	
	@media (orientation:portrait){
		padding:0;
		margin-bottom:0;
		
		.styledButton{
			width:100%;
			height:calc(100vw - 3.85vw * 2);
			
			margin:3.85vw 0;	//15
		}
	}
}

.project {
	display:flex;
	align-items:center;
	justify-content:space-between;
	
	height:100%;
	
	@media (orientation:portrait){
		flex-direction:column;
	}
	
	
	.text {
		display:flex;
		flex-direction:column;
		justify-content:center;
		
		height:100%;
		width:37.5vw;	//540
		padding:1.39vw;	//20
		margin-right:2.08vw;	//30
		
		text-align:left;
		white-space:break-spaces;
		
		@media (orientation:portrait){
			width:65%;
		}
		
		h4 {
			font-size:2.08vw;	//30
			line-height:2.08vw;	//30
			margin:0 0 1.04vw;	//15
			
			font-family: Karla;
			
			@media (orientation:portrait){
				font-size:5.13vw;	//20
				line-height:5.90vw;	//23
				margin:0 0 1.04vw;	//15
			}
		}
		
		p {
			margin:0;
			font-size: 1.25vw;	//18
			line-height: 1.74vw;	//25
			
			@media (orientation:portrait){
				display:none;
			}
		}
	}
	
	img {
		height:100%;
		width:20.83vw;	//300
		object-fit:cover;
		
		box-sizing: border-box;
		border-left: 2px solid ${() => colors.black};
		
		@media (orientation:portrait){
			width:100%;
			height:60.51vw;
		}
	}
}
`

const RenderServicePage = () => (
	<StaticQuery
	query={query}
	render={ServicesPage}
	/>
);//

export default RenderServicePage
